<template> 
  <!-- お勧めプロジェクト（漫画家、アーティスト）カルーセル -->
  <div class="carouselCreators bg-transparent relative">
    <!-- 幅640px以上画面（PC等）で表示。幅640px未満（スマホ等）で非表示。--- -->
    <!-- bc-disabled で画面が無い時灰色になる -->
    <div v-if="data.length <= 4" class="hidden sm:block">
      <a-carousel
        ref="carouselPC"
        class="mx-auto bg-disabled custom-background"
        :arrows="false" 
        :afterChange="onChange"
        :dots="false"
        :slidesToShow="numberOfPicture"
        v-if="data && data.length > 0"
      >
        <!-- スライドの表示 -->
        <div
          v-for="index in numberOfPicture" :key="index"
          class="carousel-slide text-white text-lg relative h-full cursor-pointer"
        >
          <div class="w-full h-full">
            <!-- データが存在する場合 -->
            <div v-if="data[index - 1]">
              <div class="object-scale-down">
                <img
                  class="carousel-img rounded-3xl cursor-pointer mb-3"
                  @click="$router.push(`/Creator/${data[index - 1].id}`)"
                  @touchend="$router.push(`/Creator/${data[index - 1].id}`)"
                  :src="data[index - 1].thumbnail_url"
                />
              </div>
              <div class="custom-text flex justify-center">{{ localizedName(data[index - 1]) }}</div>
            </div>
          </div>
        </div>
      </a-carousel>
    </div>
    <!-- 画像が5枚以上の場合 -->
    <div v-else class="hidden sm:block">
      <a-carousel
        ref="carouselPC"
        class="mx-auto bg-disabled custom-background"
        :arrows="true" 
        :afterChange="onChange"
        :dots="false"
        :slidesToShow="numberOfPicture"
        v-if="data && data.length > 0"
      >
        <!-- スライドの表示 -->
        <div
          v-for="item in data" :key="item.id"
          class=" carousel-slide text-white text-lg relative h-full cursor-pointer"
        >
          <div class="w-full h-full">
            <div class="object-scale-down">
              <img
                class="carousel-img rounded-3xl cursor-pointer mb-3"
                @click="$router.push(`/Creator/${item.id}`)"
                @touchend="$router.push(`/Creator/${item.id}`)"
                v-if="item.thumbnail_url"
                :src="item.thumbnail_url"
              />
            </div>
            <div class="custom-text flex justify-center">{{ localizedName(item) }} </div>
          </div>
        </div>
      </a-carousel>
    </div>
    <!-- 幅640px未満画面（スマホ等）で表示。幅640px以上（PC等）で非表示。--- -->
    <!-- bc-disabled で画面が無い時灰色になる -->
    <div class="visible w-7/12 h-full mx-auto sm:hidden">
      <a-carousel
        ref="carouselSmartPhone"
        class="mx-auto bg-disabled custom-background"
        arrows
        :afterChange="onChange"
        :dots="false"
        :slidesToShow=1
        :swipe="false"
        v-if="data && data.length > 0"
      >
        <!-- 狭い画面用カルーセル本体 -->
        <div
          v-for="item in data" :key="item.id"
          class="text-white text-sm relative h-full custom-background"
        >
          <div class="w-full h-full mx-auto">
              <img
                class="carousel-img rounded-3xl cursor-pointer flex mx-auto"
                @touchend="$router.push(`/Creator/${item.id}`)"
                v-if="item.thumbnail_url"
                :src="item.thumbnail_url"
              />
            <div class="custom-text flex justify-center mt-3">{{ localizedName(item) }} </div>
          </div>
        </div>
      </a-carousel>
    </div>
    <!-- データが無い場合 ---+---------+---------+---------+---------+------ -->
    <a-empty
      v-if="!(data && data.length > 0)"
      class="empty-symbol"
    ></a-empty>
  </div>
</template>
<!-- ----+---------+---------+---------+---------+---------+---------+------ -->
<script>
import { mapState } from 'vuex';
//-------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'carouselCreators',
  //-----+---------+---------+---------+---------+---------+---------+---------E
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    numberOfPicture: {
      type: Number,
      default: () => 1,
    },
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      selectedForPC: 0, // 要らないかも。（PC等）幅640px以上画面用カルーセルのカウンタ
      selectedForSmartPhone: 0, // 要らないかも。（スマホ等）幅640px未満画面用カルーセルのカウンタ
      slideIntervalPC: null,
      slideIntervalSmartPhone: null,
    };
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  mounted() {
    this.autoSlide();
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    autoSlide() {
      // ↓ for PC ↓
      clearInterval(this.slideIntervalPC);
      this.slideIntervalPC = setInterval(() => {
        if (this.$refs?.carouselPC) {
          //alert(`this.$refs?.carouselPC=${this.$refs?.carouselPC._uid}`);
          //alert(`this.$refs?.carouselPC=${Object.keys(this.$refs?.carouselPC)}`);
          this.$refs.carouselPC.next();
        }
      }, 100000000000000);
      // ↑ for PC ↑
      //-+---------+---------+---------+---------+---------+---------+---------E
      // ↓ for Smart Phone ↓
      clearInterval(this.slideIntervalSmartPhone);
      this.slideIntervalSmartPhone = setInterval(() => {
        if (this.$refs?.carouselSmartPhone) {
          this.$refs.carouselSmartPhone.next();
        }
      }, 100000000000000);
      // ↑ for Smart Phone ↑
    },
    //---+---------+---------+---------+---------+---------+---------+---------E
    onChange(index) {
      // 意味ないかも。
      //alert(`${index}`);
      //alert(`onChange(${this.$refs?.carouselPC.index})`);
      this.selectedForPC = index ? index : 0;
      this.selectedForSmartPhone = index ? index : 0;
    },
    //---+---------+---------+---------+---------+---------+---------+---------E
    handleClick() {
      clearInterval(this.slideIntervalPC);
      clearInterval(this.slideIntervalSmartPhone);
      setTimeout(() => {
        this.autoSlide();
      }, 5000);
    },
    //---+---------+---------+---------+---------+---------+---------+---------E
    // クリエイター名を、英語モードなら英語で、日本語モードなら日本語で返す。
    localizedName(item) {
      return this.locale === 'en' ? item.name_en : item.name_ja;
    },
    //---+---------+---------+---------+---------+---------+---------+---------E
    testAlert(objectSelected) {
      alert(`${JSON.stringify(objectSelected)}`);
    },
    //---+---------+---------+---------+---------+---------+---------+---------E
    // testAlert() {
    //   alert(`test alert(PC:${this.selectedForPC})  SmartPhone:${this.selectedForSmartPhone}`);
    // },
  }, // methods: {...}
}; // End of export default {...}
</script>
<!-- ----+---------+---------+---------+---------+---------+---------+------ -->
<style
  lang="scss"
  scoped
>
::v-deep.carouselCreators {
  height: auto; /* カルーセルの高さ */;
  @media (max-width: 768px) {
    height: auto;
  }
  font-family: 'Noto Sans CJK JP','Lato', 'Poppins', sans-serif;
  .ant-carousel {
    @screen md {
      width: 100%;
    }
    @screen 3xl {
      width: 1366px;
    }
    height: 100%;
    .slick-slider {
      width: 100%;
      height: 100%;
    }
    .slick-prev, .slick-next {
      @apply bg-white; /* 背景色を白に設定 */

      @apply p-2; /* パディングを増やして四角形を形成 */
      @apply w-10 h-10; /* 四角形の幅と高さの調整 */
      @apply flex justify-center items-center; /* 中央に矢印を配置 */
      @apply border; /* 境界線を追加 */
      @apply border-black; /* 境界線の色を黒に */
      @apply rounded-md; /* 角を丸く */
      top:35%;
      background-color: black;
      z-index: 10;
      border-radius: 100000px;  /* 丸みを帯びた角の設定 */
    }
    .slick-prev:before, .slick-next:before {
      @apply text-lg; /* 矢印のサイズを大きくする場合、ここを調整 */
      color: rgb(255, 255, 255); /* 矢印の色を黒に設定 */
    }
    .slick-next:before {
      content: ">";
    }
    .slick-prev:before {
      content: "<";
    }
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide div div img {
          height: auto;
          width: auto;
        }
      }
      .slick-slide,
      .slick-slide > div {
        height: 100%;

      }
      .carousel-img {
      overflow: hidden;  /* はみ出した画像を隠す */
      border-radius: 100000px;  /* 丸みを帯びた角の設定 */
      border: 0.3px solid black;  /* 黒いボーダーを追加 */
    }
    }
  }
  .custom-background {
    background-color:white; /* ここで好きな色を指定 */
  }
  .custom-text{
    color: black;
    font-size: 18px;
    font-weight: bold;
  }

  .carousel-slide {
    padding-left: 20px;  /* 左のスペース */
    padding-right: 20px; /* 右のスペース */
  }

  .empty-symbol {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .tba-time {
    @screen 2xl {
      min-width: 245px;
    }
  }
  .property {
    min-width: 120px;
  }

  .sale-section {
    @apply xxs:px-8 pr-8;
    &-title {
      @apply text-xl font-semibold;
    }
  }
}
</style>
